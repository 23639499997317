.main-element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;
  width: 100%;
}

h1 {
  margin: 0;
  font-size: clamp(2rem, 6vw, 3rem);
}

p {
  font-size: clamp(1rem, 2vw, 1.2rem);
  margin-top: 5px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #2c2c2c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #363636;
}

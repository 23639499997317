.error-content {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 85vh;
  text-align: center;
  padding: 20px;

  animation: fadeIn 0.8s ease-in-out;
}

.error-content h1 {
  font-size: clamp(3rem, 8vw, 4rem);
  margin-bottom: 5px;
}

.error-content p {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
}

.error-content img {
  width: 30%;
  min-width: 350px;
  max-width: 450px;
  animation: bounceIn 1s ease;
}

.error-content span {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .error-content img {
    width: 50%;
  }

  .error-content h1 {
    font-size: 4rem;
  }

  .error-content span {
    font-size: 1rem;
  }
}
